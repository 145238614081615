
.detail-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.paper-title {
    font-size: 24px;
    line-height: 1;
    text-align: center;
    padding: 4px 0 24px;
    border-bottom: 2px solid #E5E5E5;
    position: relative;
    margin-bottom: 30px;
    .backBtn {
        position: absolute;
        right: 20px;
        bottom: 10px;
    }
}
